import React, { PropsWithChildren } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { LocalFormSchema } from "../logic/applicationUpdateSchema";
import { Alert, Button } from "@mui/material";
import { flattenErrors } from "../utils/forms";

type Console = {
  showTestingInfoBlocks: boolean;
  setShowTestingInfoBlocks: (show: boolean) => void;
}

const defaultConsole: Console = {
  showTestingInfoBlocks: process.env.REACT_APP_TESTING === 'true',
  setShowTestingInfoBlocks: () => { },
}

const ConsoleContext = React.createContext<Console>(defaultConsole);

export const ConsoleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showTestingInfoBlocks, setShowTestingInfoBlocks] = React.useState(defaultConsole.showTestingInfoBlocks);

  const console: Console = {
    showTestingInfoBlocks,
    setShowTestingInfoBlocks,
  }

  // Export the Console object to `window` so it can be used in the Browser Console
  // eslint-disable-next-line
  const anyWindow: any = window as any
  anyWindow.Console = console

  return (
    <ConsoleContext.Provider value={console}>
      {children}
    </ConsoleContext.Provider>
  )
}

export const TestingInfoBlock: React.FC<PropsWithChildren> = ({ children }) => {
  const console = React.useContext(ConsoleContext)
  return console.showTestingInfoBlocks
    ? <>{children}</>
    : null
}

export const TestingInfoBlockFormErrors = <T extends FieldValues>({ form }: { form: UseFormReturn<T> }) => {
  const errorCount = flattenErrors(form.formState.errors).length

  return (
    <TestingInfoBlock>
      <Alert severity="info" sx={{ marginTop: 3 }}>
        There {errorCount === 1 ? `is 1 error` : `are ${errorCount} errors`} in the {errorCount === 0 ? 'valid' : 'invalid'} form.
        {errorCount > 0 && (
          <ul>
            {flattenErrors(form.formState.errors).map((error, idx) => (
              <li key={idx}><strong>{error.path}:</strong> {error.message}</li>
            ))}
          </ul>
        )}
        <Button onClick={() => form.trigger()}>Re-validate Form</Button>
      </Alert>
    </TestingInfoBlock>
  )
}

export default ConsoleProvider
