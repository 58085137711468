import { ComponentProps, useState } from "react"
import { Autocomplete, TextField } from "@mui/material"
import { FieldValues, UseControllerProps, useController } from "react-hook-form-mui"
import { apiClientHooks } from "../bootstrapping/InitApiClient"
import { useDebounce } from "@uidotdev/usehooks"

type CustomerAutocompleteProps<T extends FieldValues> =
  Omit<
    ComponentProps<typeof Autocomplete>,
    // These are overriden by the component
    'options' | 'renderInput' | 'freeSolo' | 'value' | 'onChange' | 'isOptionEqualToValue' | 'onInputChange' |
    // These are not available with freeSolo enabled
    'renderOption' | 'renderTags'
  > &
  UseControllerProps<T> &
  {
    label: ComponentProps<typeof TextField>['label'],
  }

const CustomerAutocomplete = <T extends FieldValues>(props: CustomerAutocompleteProps<T>) => {
  // Split the UseControllerProps vs AutocompleteProps
  const { name, rules, control, label, shouldUnregister, defaultValue, ...autocompleteProps } = props;

  // Controller
  const {
    field: { ref, value, onChange },
    formState: { errors: { [name]: error } },
  } = useController<T>({ name, control });

  // Throttle the input value changes to avoid too many API calls
  const [inputValue, setInputValue] = useState<string | null | undefined>(null)
  const debouncedInputValue = useDebounce(inputValue, 300 /* milliseconds */)

  // Call the API to get the customers based on the debounced input value
  const customers = apiClientHooks.useGetCustomers({
    queries: {
      globalFilter: debouncedInputValue ?? '',
      columnFilters: [{ id: 'active', value: true }],
    },
  }, {
    refetchOnWindowFocus: false,
    enabled: !!debouncedInputValue,
  })

  const options = customers.data?.rows.map(customer => ({
    id: customer.id,
    label: customer.name,
  })) ?? []

  const getOptionFrom = (value: string | null | undefined): typeof options[number] | string => {
    if (value == null) return ''
    return options.find(option => option.id === value) ?? ''
  }

  console.log("CustomerAutocomplete: ", { inputValue, value, error })

  return (
    <Autocomplete
      {...autocompleteProps}
      ref={ref}
      freeSolo // Allows the free text input to stay after loosing focus
      options={options}
      value={getOptionFrom(value)}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      isOptionEqualToValue={(option, value) => {
        console.log("isOptionEqualToValue: ", { option, value })
        // We return true for the first option if the value is not set or is empty
        if ((value == null || value === '') && option === options[0]) {
          return value == null || value === ''
        }

        // If the value is a string and the option is an object, we match based on the option id
        if (typeof value === 'string' && typeof option === 'object' && option != null && 'id' in option) {
          return option.id === value
        }

        // If the value is an object, we do an exact match
        return option === value
      }}
      onChange={(_, selectedOption: typeof options[number] | unknown) => {
        const updatedValue = (selectedOption as any)?.id ?? ''
        // TODO: We should probably do some more aggressive type checking here, to avoid runtime errors
        onChange(updatedValue)
      }}
      renderInput={(params) => <>
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error && `${error.message}`}
        />
        <input type="hidden" name={name} value={value} />
      </>}
    />
  )
}

export default CustomerAutocomplete
