import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, AlertTitle, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { TextFieldElement, useForm } from "react-hook-form-mui"
import { z } from "zod"
import SingleClickWaitingButton from "../components/SingleClickWaitingButton"
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"

type ChangeTestApiKeyProps = {
  testApiKey: string | undefined,
  setTestApiKey: React.Dispatch<React.SetStateAction<string | undefined>>,
}
const ChangeTestApiKey: React.FC<ChangeTestApiKeyProps> = ({ testApiKey, setTestApiKey }) => {
  const clearTestApiKey = () => {
    // TODO: Implement
    setTestApiKey(undefined)
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getTestDataSnapshots') })
  }

  const zFormSchema = z.object({
    _testApiKey: z.string(),
  })
  type FormSchema = z.infer<typeof zFormSchema>
  const form = useForm<FormSchema>({
    resolver: zodResolver(zFormSchema),
    defaultValues: {
      _testApiKey: testApiKey || '',
    }
  })

  const [showKey, setShowKey] = useState(false)

  return testApiKey
    ? (
      <Alert severity="success" sx={{ marginBottom: 3 }}>
        <AlertTitle>Test API Key</AlertTitle>
        {showKey
          ? <>
            Key: <strong>{testApiKey}</strong>
            <br />
          </>
          : <>
            Key: <em>REDACTED</em>
            <br />
          </>}
        <SingleClickWaitingButton
          variant='contained'
          sx={{ marginTop: 2, marginRight: 2 }}
          onClick={() => setShowKey(currentValue => !currentValue)}
        >
          {showKey ? 'Hide' : 'Show'}
        </SingleClickWaitingButton>
        <SingleClickWaitingButton
          variant='contained'
          sx={{ marginTop: 2 }}
          onClick={() => clearTestApiKey()}
        >
          Clear
        </SingleClickWaitingButton>
      </Alert >
    )
    : (
      <Alert severity="error" sx={{ marginBottom: 3 }}>
        <AlertTitle>Test API Key Required</AlertTitle>
        <TextFieldElement
          control={form.control}
          label="Test API Key"
          name="_testApiKey"
          required
          fullWidth
          sx={{ marginTop: 1 }}
        />
        <SingleClickWaitingButton
          variant='contained'
          sx={{ marginTop: 2 }}
          onClick={form.handleSubmit((data) => {
            setTestApiKey(data._testApiKey)
          })}
          disabled={!form.formState.isValid}
        >
          Save
        </SingleClickWaitingButton>
      </Alert>
    )
}

const localStorageKey = `smartdbs/${process.env.REACT_APP_NAME_STUB}/testApiKey`

const useTestApiKey = () => {
  const storedTestApiKey = localStorage.getItem(localStorageKey) ?? undefined
  const [testApiKey, setTestApiKey] = useState<string | undefined>(storedTestApiKey)

  useEffect(() => {
    if (testApiKey !== storedTestApiKey) {
      if (testApiKey) {
        localStorage.setItem(localStorageKey, testApiKey)
      } else {
        localStorage.removeItem(localStorageKey)
      }
    }
  }, [storedTestApiKey, testApiKey])

  const TestingSetTestApiKey: React.FC = useCallback(() => {
    return <ChangeTestApiKey testApiKey={testApiKey} setTestApiKey={setTestApiKey} />
  }, [testApiKey, setTestApiKey])

  return {
    testApiKey, TestingSetTestApiKey
  }
}

export default useTestApiKey
