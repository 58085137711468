import React from "react";
import { Alert, AlertTitle, Box, Button, Container, Typography } from "@mui/material";
import { Form, useNavigate, useParams } from "react-router-dom";
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { isSomething } from "../utils/utils";
import { useProfileAssured } from "../bootstrapping/InitRequireProfile";
import { queryClient } from "../bootstrapping/InitReactQuery";

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { application_id } = useParams<{ application_id?: string }>()
  if (!application_id) throw new Error('application_id is required')

  const profile = useProfileAssured()
  if (profile.countersignatory_number == null) {
    throw new Error('You are not a countersignatory. You should not be here.')
  }

  // Get all the details related to the application
  // - The application itself
  // - The job the application is for
  // - The customer the job is for
  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({ params: { id: application_id } })
  const application = getEBulkApplicationByID.data

  const getJobs = apiClientHooks.useGetJobs()
  const job = getJobs.data?.rows.find(j => j.id === getEBulkApplicationByID.data?.job_id)

  // Load the Customer to determine if payment by invoice is allowed
  const getCustomers = apiClientHooks.useGetCustomers()
  const customer = getCustomers.data?.rows.find(c => c.id === job?.customer_id)

  const countersignEBulkApplicationByID = apiClientHooks.useCountersignEBulkApplicationByID({
    params: {
      id: application_id,
    },
  })

  const doCountersigning = async () => {
    await countersignEBulkApplicationByID.mutateAsync(undefined, {})
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') }) // Don't wait for this to finish
    navigate('/')
  }

  return <>
    <Helmet>
      <title>Countersigning</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" marginBottom={3}>
            Counter signing
          </Typography>
          <Typography component="h1" variant="h6" marginBottom={3}>
            TODO: Display the customer details here<br/><br/>
            <strong>{customer?.name}</strong>
          </Typography>
          <Typography component="h1" variant="h6" marginBottom={3}>
            TODO: Display the job role details here<br/><br/>
            <strong>{job?.role}</strong>
          </Typography>
          <Typography component="h1" variant="h6" marginBottom={3}>
            TODO: Display the application details here<br/><br/>
            <strong>{application?.data.Forename} {application?.data.PresentSurname}</strong>
          </Typography>
          <Alert severity="success" sx={{ marginTop: 2 }}>
            <AlertTitle>Countersigning</AlertTitle>
            Please confirm that you <strong>{profile.given_name} {profile.family_name}</strong> wish to countersign this application.<br/><br/>
            <em>TODO: check the wording needed</em>
            <br />
            <br />
            <Button variant='contained' onClick={doCountersigning}>
              Countersign Application
            </Button>
          </Alert>
        </Box>
      </Form>
    </Container>
  </>
}

export default Element
