import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useTestApiKey from "../hooks/useTestApiKey";
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import { queryClient } from "../bootstrapping/InitReactQuery";
import { useNavigate } from "react-router-dom";

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { testApiKey, TestingSetTestApiKey } = useTestApiKey()

  const getTestDataSnapshots = apiClientHooks.useGetTestDataSnapshots({
    headers: {
      'X-Test-API-Key': testApiKey,
    },
  }, {
    enabled: testApiKey !== undefined,
  })
  const snapshots = getTestDataSnapshots.data

  return <>
    <Helmet>
      <title>Testing</title>
    </Helmet>
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TestingSetTestApiKey />
        {testApiKey === undefined
          ? <>
            A Test API Key is needed to use this page.
          </>
          : <>
            {(snapshots ?? [])
              .map((snapshot, index) => (
                <SingleClickWaitingButton
                  sx={{ marginBottom: 3, width: '30%' }}
                  key={index}
                  variant="contained"
                  onClick={async () => {
                    console.log("Restoring snapshot: ", snapshot)
                    await apiClient.restoreTestDataSnapshot(undefined, {
                      queries: {
                        name: snapshot.name,
                      },
                      headers: {
                        'X-Test-API-Key': testApiKey,
                      },
                    })
                    // Clear the board!
                    await queryClient.invalidateQueries()
                    // Jump to the homepage
                    navigate('/')
                  }}
                >
                  {snapshot.name}
                </SingleClickWaitingButton>
              ))
            }
          </>}
      </Box>
    </Container>
  </>
}

export default Element
