import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { z } from 'zod';
import { TextFieldElement, useForm } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Profile } from '../routes/Profiles';
import { apiClient, apiClientHooks } from '../bootstrapping/InitApiClient';
import { queryClient } from '../bootstrapping/InitReactQuery';
import { useProfileAssured } from '../bootstrapping/InitRequireProfile';

const AddCountersignatoryButton: React.FC<{ profile: Profile }> = ({ profile }) => {
  const myProfile = useProfileAssured()

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const zLocalFormScheman = z.object({
    countersignatory_number: z.string().regex(/^\d{11}$/, 'Must be exactly 11 digits'),
  })
  type LocalFormScheman = z.infer<typeof zLocalFormScheman>
  const form = useForm<LocalFormScheman>({
    resolver: zodResolver(zLocalFormScheman),
    mode: 'onBlur',
    defaultValues: profile,
  })

  if (profile.is_staff) {
    return (
      <div>
        <Button onClick={handleOpen}>Set Number</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Set Countersignatory Number
            </Typography>
            <Typography id="modal-modal-subtitle" sx={{ mb: 2 }}>
              For {profile.given_name} {profile.family_name}
            </Typography>
            <TextFieldElement
              control={form.control}
              name="countersignatory_number"
              label="Countersignatory Number"
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                form.handleSubmit(async (data) => {
                  await apiClient.addCountersignatoryNumberToProfile(undefined, {
                    params: { id: profile.id },
                    queries: { countersignatory_number: data.countersignatory_number },
                  })
                  queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getProfiles') })
                  if (profile.id === myProfile.id) {
                    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfile') })
                  }
                  setOpen(false)
                })()
              }}
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>
        </Modal>
      </div>
    )
  } else {
    return null
  }
}

export default AddCountersignatoryButton
