import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient"
import { Alert } from "@mui/material"
import { refetchProfileJwt } from "../../bootstrapping/InitRequireProfile"
import { useIdTokenClaims } from "../auth0/useIdTokenClaims"
import SingleClickWaitingButton from "../SingleClickWaitingButton"
import LoadingSpinner from "../LoadingSpinner"

const ShowInvites: React.FC<{ noInvites: React.ReactNode }> = ({ noInvites }) => {
  const { idToken } = useIdTokenClaims()

  // Use the idToken to get my invites
  const getMyInvites = apiClientHooks.useGetMyInvites({
    headers: { 'x-auth0-id-token': idToken ?? '' },
  }, {
    enabled: !!idToken,
  })

  if (getMyInvites.isInitialLoading) return <LoadingSpinner name='ShowInvites' />

  // If there's no data after initial loading (such as an error), default to no invites
  const invites = getMyInvites.data ?? []

  // We only care about Staff Invites
  const staffInvites = invites.filter(invite => invite.type === 'staff')

  // Handler for Accepting the invite
  const onClickAccept = (invite: { id: string }) => async () => {
    if (!idToken) throw new Error('Should not happen: no ID token!')

    const result = await apiClient.acceptInvite(undefined, {
      params: { id: invite.id },
      headers: { 'x-auth0-id-token': idToken },
    })
    
    if (result.accepted) {
      refetchProfileJwt()
    }
  }

  return <>
    {staffInvites.length === 0 && noInvites}
    {staffInvites.map(invite => (
      <Alert key={invite.id} severity="success" sx={{ marginBottom: 3 }}>
        You have been invited to join the staff of {process.env.REACT_APP_NAME_STUB}.
        <br /><br />
        <SingleClickWaitingButton variant="contained" color="primary" disabled={!idToken} onClick={onClickAccept(invite)}>Accept</SingleClickWaitingButton>
      </Alert>
    ))}
  </>
}

export default ShowInvites
