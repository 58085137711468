import React, { PropsWithChildren } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Container, Box, Avatar, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useRegisterAuthTokenProvider } from "./InitApiClient";
import { Helmet } from "react-helmet-async";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_API_AUDIENCE,
} = process.env

if (!REACT_APP_AUTH0_DOMAIN) throw new Error('REACT_APP_AUTH0_DOMAIN is not set')
if (!REACT_APP_AUTH0_CLIENT_ID) throw new Error('REACT_APP_AUTH0_CLIENT_ID is not set')
if (!REACT_APP_AUTH0_API_AUDIENCE) throw new Error('REACT_APP_AUTH0_API_AUDIENCE is not set')

const RegisterAuth0TokenProvider: React.FC = () => {
  const auth0 = useAuth0()

  useRegisterAuthTokenProvider(
    'auth0',
    async () => auth0.getAccessTokenSilently(),
    [auth0],
  )

  return null
}

const Login: React.FC = () => {
  const auth0 = useAuth0()

  return <>
    <Helmet>
      <title>DBS Login</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to the {process.env.REACT_APP_NAME_STUB} Portal
        </Typography>

        <SingleClickWaitingButton
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => auth0.loginWithRedirect()}
        >
          Sign In
        </SingleClickWaitingButton>
      </Box>
    </Container>
  </>
}

const LoginIfNeeded: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, isAuthenticated, error } = useAuth0()

  if (isLoading) {
    if (process.env.NODE_ENV === 'development') console.log('[initAuth0] Auth0 is Loading...')
    return null // We don't want to flash the login page while we're loading state
  }

  if (error) {
    if (process.env.NODE_ENV === 'development') console.log('[initAuth0] Auth0 had an Error...')
    return <div>Oops... {error.message}</div>
  }

  if (process.env.NODE_ENV === 'development') console.log('[initAuth0] Auth0 is Ready!')

  if (!isAuthenticated) {
    if (process.env.NODE_ENV === 'development') console.log('[initAuth0] Login is required...')
    return <Login />
  }

  if (process.env.NODE_ENV === 'development') console.log('[initAuth0] Login is complete!')
  return <>{children}</>
}

const InitAuth0RequiringLogin: React.FC<PropsWithChildren> = ({ children }) =>
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: REACT_APP_AUTH0_API_AUDIENCE,
    }}
  >
    <RegisterAuth0TokenProvider />
    <LoginIfNeeded>
      {children}
    </LoginIfNeeded>
  </Auth0Provider>


export default InitAuth0RequiringLogin
