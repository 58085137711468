import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const columnFilters = z
  .array(
    z.union([
      z.object({ id: z.enum(["active", "is_staff"]), value: z.boolean() }),
      z.object({
        id: z.enum(["email", "given_name", "family_name"]),
        value: z.string(),
      }),
      z.object({
        id: z.enum(["known_customer_id", "admin_customer_id"]),
        value: z.string().uuid(),
      }),
      z.object({
        id: z.enum(["created_at", "updated_at"]),
        from: z.string().datetime({ offset: true }).optional(),
        to: z.string().datetime({ offset: true }).optional(),
      }),
    ])
  )
  .optional();
const Profile = z.object({
  id: z.string(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
  active: z.boolean(),
  is_staff: z.boolean(),
  known_customer_ids: z.array(z.string()),
  admin_customer_ids: z.array(z.string()),
  email: z.string(),
  given_name: z.string(),
  family_name: z.string(),
  countersignatory_number: z.string().optional(),
  data: z.object({}).partial(),
});
const _PageableMeta = z.object({
  totalRowCount: z.number().int(),
  page: z.object({ size: z.number().int(), index: z.number().int() }),
});
const Sort_GetProfiles = z.enum([
  "created_at asc",
  "created_at desc",
  "updated_at asc",
  "updated_at desc",
  "active asc",
  "active desc",
]);
const ColumnFilters_GetProfiles = z.array(
  z.union([
    z.object({ id: z.enum(["active", "is_staff"]), value: z.boolean() }),
    z.object({
      id: z.enum(["email", "given_name", "family_name"]),
      value: z.string(),
    }),
    z.object({
      id: z.enum(["known_customer_id", "admin_customer_id"]),
      value: z.string().uuid(),
    }),
    z.object({
      id: z.enum(["created_at", "updated_at"]),
      from: z.string().datetime({ offset: true }).optional(),
      to: z.string().datetime({ offset: true }).optional(),
    }),
  ])
);
const ReturnType_GetProfiles = z.object({
  rows: z.array(
    Profile.and(
      z.object({
        known_customer_names: z.array(z.string()),
        admin_customer_names: z.array(z.string()),
      })
    )
  ),
  meta: _PageableMeta,
  sort: Sort_GetProfiles.optional(),
  globalFilter: z.string().optional(),
  columnFilters: ColumnFilters_GetProfiles.optional(),
});
const ErrorResponse = z.object({
  error: z.object({ type: z.string(), message: z.string() }).partial(),
});
const ProfileResponse = z.object({
  id: z.string(),
  identity_ids: z.array(z.string()),
  is_staff: z.boolean(),
  known_customer_ids: z.array(z.string()),
  admin_customer_ids: z.array(z.string()),
  email: z.string(),
  given_name: z.string(),
  family_name: z.string(),
  countersignatory_number: z.string().optional(),
  data: z.object({}).partial(),
  jwt: z.string().optional(),
});
const createMyProfile_Body = z.object({
  given_name: z.string(),
  family_name: z.string(),
});
const ApplicationStats = z.object({
  totalDraft: z.number().int(),
  totalAwaitingCustomerApproval: z.number().int(),
  totalAwaitingPayment: z.number().int(),
  totalAwaitingStaffApproval: z.number().int(),
  totalAwaitingDBSSubmission: z.number().int(),
  totalAwaitingDBSResponse: z.number().int(),
  totalRejected: z.number().int(),
  totalCompleted: z.number().int(),
});
const ReturnType_GetGlobalStats = z.object({
  stats: z.object({
    customers: z.object({ totalActive: z.number().int() }),
    jobs: z.object({ totalActive: z.number().int() }),
    invites: z.object({
      totalActiveStaff: z.number().int(),
      totalActiveCustomers: z.number().int(),
      totalActiveApplicants: z.number().int(),
    }),
    applications: ApplicationStats,
  }),
});
const NewInviteStaff = z.object({
  type: z.literal("staff"),
  email: z.string(),
});
const MyInviteStaff = z.object({ id: z.string() }).and(NewInviteStaff);
const NewInviteCustomer = z.object({
  id: z.string().optional(),
  type: z.literal("customer"),
  email: z.string(),
  customer_id: z.string(),
});
const MyInviteCustomer = z
  .object({ id: z.string(), customer_name: z.string() })
  .and(NewInviteCustomer);
const NewInviteApplicant = z.object({
  id: z.string().optional(),
  type: z.literal("applicant"),
  email: z.string(),
  customer_id: z.string(),
  job_id: z.string(),
});
const MyInviteApplicant = z
  .object({ id: z.string(), customer_name: z.string(), job_role: z.string() })
  .and(NewInviteApplicant);
const columnFilters__2 = z
  .array(
    z.union([
      z.object({
        id: z.literal("type"),
        value: z.enum(["staff", "customer", "applicant"]),
      }),
      z.object({ id: z.literal("active"), value: z.boolean() }),
      z.object({
        id: z.enum([
          "email",
          "customer_name",
          "job_role",
          "invited_by_profile_name",
        ]),
        value: z.string(),
      }),
      z.object({
        id: z.enum(["accepted_at", "invited_at"]),
        from: z.string().datetime({ offset: true }).optional(),
        to: z.string().datetime({ offset: true }).optional(),
      }),
    ])
  )
  .optional();
const _InviteCommon = z.object({
  id: z.string(),
  active: z.boolean(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
  accepted_at: z.string().datetime({ offset: true }).optional(),
  accepted_by_profile_id: z.string().optional(),
  accepted_by_profile_name: z.string().optional(),
  invited_by_profile_id: z.string(),
  invited_by_profile_name: z.string(),
  invited_at: z.string().datetime({ offset: true }),
});
const InviteStaff = _InviteCommon.and(NewInviteStaff);
const InviteCustomer = _InviteCommon
  .and(NewInviteCustomer)
  .and(z.object({ customer_name: z.string() }).partial());
const InviteApplicant = _InviteCommon
  .and(NewInviteApplicant)
  .and(z.object({ customer_name: z.string(), job_role: z.string() }).partial());
const Sort_GetInvites = z.enum([
  "type asc",
  "type desc",
  "active asc",
  "active desc",
  "accepted_at asc",
  "accepted_at desc",
  "email asc",
  "email desc",
  "customer_name asc",
  "customer_name desc",
  "job_role asc",
  "job_role desc",
  "invited_at asc",
  "invited_at desc",
  "invited_by_profile_name asc",
  "invited_by_profile_name desc",
]);
const ColumnFilters_GetInvites = z.array(
  z.union([
    z.object({
      id: z.literal("type"),
      value: z.enum(["staff", "customer", "applicant"]),
    }),
    z.object({ id: z.literal("active"), value: z.boolean() }),
    z.object({
      id: z.enum([
        "email",
        "customer_name",
        "job_role",
        "invited_by_profile_name",
      ]),
      value: z.string(),
    }),
    z.object({
      id: z.enum(["accepted_at", "invited_at"]),
      from: z.string().datetime({ offset: true }).optional(),
      to: z.string().datetime({ offset: true }).optional(),
    }),
  ])
);
const ReturnType_GetInvites = z.object({
  rows: z.array(z.union([InviteStaff, InviteCustomer, InviteApplicant])),
  meta: _PageableMeta,
  sort: Sort_GetInvites.optional(),
  globalFilter: z.string().optional(),
  columnFilters: ColumnFilters_GetInvites.optional(),
});
const createInvite_Body = z.union([
  NewInviteStaff,
  NewInviteCustomer,
  NewInviteApplicant,
]);
const columnFilters__3 = z
  .array(
    z.union([
      z.object({
        id: z.enum(["active", "allow_pay_by_invoice"]),
        value: z.boolean(),
      }),
      z.object({ id: z.enum(["name", "pin"]), value: z.string() }),
      z.object({
        id: z.enum(["created_at", "updated_at"]),
        from: z.string().datetime({ offset: true }).optional(),
        to: z.string().datetime({ offset: true }).optional(),
      }),
    ])
  )
  .optional();
const Customer = z.object({
  id: z.string(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
  active: z.boolean(),
  name: z.string(),
  pin: z.string().optional(),
  allow_pay_by_invoice: z.boolean(),
  sector_healthcare: z.boolean(),
  enable_applicant_document_selector: z.boolean(),
  enable_applicant_payment: z.boolean(),
});
const Sort_GetCustomers = z.enum([
  "name asc",
  "name desc",
  "created_at asc",
  "created_at desc",
  "updated_at asc",
  "updated_at desc",
  "active asc",
  "active desc",
]);
const ColumnFilters_GetCustomers = z.array(
  z.union([
    z.object({
      id: z.enum(["active", "allow_pay_by_invoice"]),
      value: z.boolean(),
    }),
    z.object({ id: z.enum(["name", "pin"]), value: z.string() }),
    z.object({
      id: z.enum(["created_at", "updated_at"]),
      from: z.string().datetime({ offset: true }).optional(),
      to: z.string().datetime({ offset: true }).optional(),
    }),
  ])
);
const ReturnType_GetCustomers = z.object({
  rows: z.array(Customer),
  meta: _PageableMeta,
  sort: Sort_GetCustomers.optional(),
  globalFilter: z.string().optional(),
  columnFilters: ColumnFilters_GetCustomers.optional(),
});
const Field_AddressLineType = z.string();
const NewCustomer = z.object({
  name: Field_AddressLineType.min(1)
    .max(60)
    .regex(
      /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
    ),
  pin: z.string().optional(),
  allow_pay_by_invoice: z.boolean(),
  sector_healthcare: z.boolean(),
  enable_applicant_document_selector: z.boolean(),
  enable_applicant_payment: z.boolean(),
});
const ReturnType_GetCustomerExpanded = z.object({
  admin_profiles: z.array(
    z.object({
      id: z.string().uuid(),
      given_name: z.string(),
      family_name: z.string(),
    })
  ),
  customer: z.object({ id: z.string().uuid() }),
  applications: ApplicationStats,
});
const columnFilters__4 = z
  .array(
    z.union([
      z.object({
        id: z.literal("application_type"),
        value: z.enum(["standard", "enhanced"]),
      }),
      z.object({
        id: z.literal("application_workforce"),
        value: z.enum(["adult_only", "child_only", "child_and_adult", "other"]),
      }),
      z.object({
        id: z.enum([
          "active",
          "working_with_children",
          "working_with_adults",
          "working_at_home_address",
          "volunteer",
        ]),
        value: z.boolean(),
      }),
      z.object({ id: z.literal("customer_id"), value: z.string().uuid() }),
      z.object({ id: z.enum(["customer_name", "role"]), value: z.string() }),
      z.object({
        id: z.enum(["created_at", "updated_at"]),
        from: z.string().datetime({ offset: true }).optional(),
        to: z.string().datetime({ offset: true }).optional(),
      }),
    ])
  )
  .optional();
const GenericObject = z.object({
  id: z.string(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
  active: z.boolean(),
});
const NewJob = z.object({
  customer_id: z.string().min(36).max(36),
  role: Field_AddressLineType.min(1)
    .max(60)
    .regex(
      /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
    ),
  application_workforce: z.enum([
    "adult_only",
    "child_only",
    "child_and_adult",
    "other",
  ]),
  application_workforce_justification: z.string().optional(),
  application_type: z.enum(["standard", "enhanced"]),
  application_type_justification: z.string().optional(),
  working_with_children: z.boolean(),
  working_with_children_justification: z.string().optional(),
  working_with_adults: z.boolean(),
  working_with_adults_justification: z.string().optional(),
  working_at_home_address: z.boolean(),
  working_at_home_address_justification: z.string().optional(),
  volunteer: z.boolean(),
  with_adult_first: z.boolean(),
});
const Job = GenericObject.and(NewJob).and(
  z.object({ customer_name: z.string() }).partial()
);
const Sort_GetJobs = z.enum([
  "role asc",
  "role desc",
  "application_workforce asc",
  "application_workforce desc",
  "application_type asc",
  "application_type desc",
  "working_with_adults asc",
  "working_with_adults desc",
  "working_with_children asc",
  "working_with_children desc",
  "working_at_home_address asc",
  "working_at_home_address desc",
  "volunteer asc",
  "volunteer desc",
  "customer_name asc",
  "customer_name desc",
  "created_at asc",
  "created_at desc",
  "updated_at asc",
  "updated_at desc",
  "active asc",
  "active desc",
]);
const ColumnFilters_GetJobs = z.array(
  z.union([
    z.object({
      id: z.literal("application_type"),
      value: z.enum(["standard", "enhanced"]),
    }),
    z.object({
      id: z.literal("application_workforce"),
      value: z.enum(["adult_only", "child_only", "child_and_adult", "other"]),
    }),
    z.object({
      id: z.enum([
        "active",
        "working_with_children",
        "working_with_adults",
        "working_at_home_address",
        "volunteer",
      ]),
      value: z.boolean(),
    }),
    z.object({ id: z.literal("customer_id"), value: z.string().uuid() }),
    z.object({ id: z.enum(["customer_name", "role"]), value: z.string() }),
    z.object({
      id: z.enum(["created_at", "updated_at"]),
      from: z.string().datetime({ offset: true }).optional(),
      to: z.string().datetime({ offset: true }).optional(),
    }),
  ])
);
const ReturnType_GetJobs = z.object({
  rows: z.array(Job),
  meta: _PageableMeta,
  sort: Sort_GetJobs.optional(),
  globalFilter: z.string().optional(),
  columnFilters: ColumnFilters_GetJobs.optional(),
});
const ReturnType_GetJobExpanded = z.object({
  job: z.object({
    id: z.string().uuid(),
    application_workforce_justification: z.string().optional(),
    application_type_justification: z.string().optional(),
    working_with_adults_justification: z.string().optional(),
    working_with_children_justification: z.string().optional(),
    working_at_home_address_justification: z.string().optional(),
    last_checked_at: z.string().datetime({ offset: true }).optional(),
    last_checked_by_profile_id: z.string().uuid().optional(),
    last_checked_by_profile_name: z.string().optional(),
  }),
  applications: ApplicationStats,
});
const columnFilters__5 = z
  .array(
    z.union([
      z.object({
        id: z.literal("status"),
        value: z.enum([
          "draft",
          "awaiting_customer_approval",
          "awaiting_payment",
          "awaiting_staff_approval",
          "awaiting_submission_to_DBS",
          "awaiting_response_from_DBS",
          "rejected",
          "completed",
        ]),
      }),
      z.object({
        id: z.literal("payment_method"),
        value: z.enum(["paypal", "invoice"]),
      }),
      z.object({ id: z.literal("active"), value: z.boolean() }),
      z.object({
        id: z.enum(["customer_id", "job_id"]),
        value: z.string().uuid(),
      }),
      z.object({
        id: z.enum([
          "customer_name",
          "job_role",
          "role",
          "payment_reference",
          "profile_given_name",
          "profile_family_name",
        ]),
        value: z.string(),
      }),
      z.object({
        id: z.enum(["created_at", "updated_at", "payment_at"]),
        from: z.string().datetime({ offset: true }).optional(),
        to: z.string().datetime({ offset: true }).optional(),
      }),
    ])
  )
  .optional();
const TitleType = z.enum([
  "BARON",
  "BARONESS",
  "BRIGADIER",
  "CANON",
  "CAPTAIN",
  "DR",
  "DUCHESS",
  "DUKE",
  "ESQ",
  "FATHER",
  "HON",
  "INSPECTOR",
  "LADY",
  "LORD",
  "LT COL",
  "MAJOR",
  "MISS",
  "MOST REVER",
  "MR",
  "MRS",
  "MS",
  "PASTOR",
  "PROFESSOR",
  "RABBI",
  "REV DR",
  "REVEREND",
  "RT REVEREN",
  "SIR",
  "SISTER",
  "SQUADRON L",
  "WG CDR",
]);
const ForenameType = z.string();
const MiddlenamesStructure = z
  .object({ Middlename: z.array(ForenameType).min(1).max(3) })
  .passthrough();
const SurnameType = z.string();
const ContemporaryDateType = z.string();
const GenderType = z.enum(["male", "female"]);
const NationalInsuranceNumberType = z.string();
const AddressLineType = z.string();
const ShortAddressLineType = z.string();
const PostCodeType = z.string();
const ISOCountryCodeType = z.enum([
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AN",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BM",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "FX",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LX",
  "LY",
  "MA",
  "MC",
  "MD",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "ST",
  "SV",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TM",
  "TN",
  "TO",
  "TP",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "YE",
  "YT",
  "YU",
  "ZA",
  "ZM",
  "ZR",
  "ZW",
]);
const AddressStructure = z
  .object({
    AddressLine1: AddressLineType.min(1)
      .max(60)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    AddressTown: ShortAddressLineType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    AddressCounty: ShortAddressLineType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      )
      .optional(),
    Postcode: PostCodeType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/']+)|([A-Z0-9\(\)\-/'][A-Z 0-9\(\)\-/']*[A-Z0-9\(\)\-/'])/
      )
      .optional(),
    CountryCode: ISOCountryCodeType,
    AddressLine2: AddressLineType.min(1)
      .max(60)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      )
      .optional(),
  })
  .passthrough();
const ContemporaryYearMonthType = z.string();
const CurrentAddressDateStructure = z
  .object({
    Address: AddressStructure,
    ResidentFromGyearMonth: ContemporaryYearMonthType.regex(/\d{4}-\d{2}/),
  })
  .passthrough();
const ResidentDateRangeStructure = z
  .object({
    ResidentFromGyearMonth: ContemporaryYearMonthType.regex(/\d{4}-\d{2}/),
    ResidentToGyearMonth: ContemporaryYearMonthType.regex(/\d{4}-\d{2}/),
  })
  .passthrough();
const PreviousAddressDateStructure = z
  .object({
    Address: AddressStructure,
    ResidentDates: ResidentDateRangeStructure,
  })
  .passthrough();
const ContemporaryYearType = z.string();
const PhoneNumberType = z.string();
const YesNoType = z.enum(["y", "n"]);
const LanguagePreferenceType = z.enum(["english", "welsh"]);
const OtherNamesStructure = z
  .object({
    Name: ForenameType.min(1)
      .max(60)
      .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/),
    UsedFrom: ContemporaryYearType.regex(/\d{4}/),
    UsedTo: ContemporaryYearType.regex(/\d{4}/).optional(),
  })
  .passthrough();
const OtherForenames = z
  .object({ OtherForename: z.array(OtherNamesStructure).min(1).max(200) })
  .passthrough();
const OtherSurnames = z
  .object({ OtherSurname: z.array(OtherNamesStructure).min(1).max(200) })
  .passthrough();
const ISOCountryDescType = z.string();
const CheckedByType = z.string();
const PassportNumberType = z.string();
const PassportStructure = z
  .object({
    PassportNumber: PassportNumberType.min(1)
      .max(11)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    PassportDob: ContemporaryDateType.regex(/\d{4}-\d{2}-\d{2}/),
    PassportNationality: ISOCountryDescType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    PassportIssueDate: ContemporaryDateType.regex(/\d{4}-\d{2}-\d{2}/),
  })
  .passthrough();
const DriverLicenceNumberType = z.string();
const DriverLicenceTypeType = z.enum(["paper", "photo"]);
const DriverLicenceStructure = z
  .object({
    DriverLicenceNumber: DriverLicenceNumberType.min(1)
      .max(18)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    DriverLicenceDOB: ContemporaryDateType.regex(/\d{4}-\d{2}-\d{2}/),
    DriverLicenceType: DriverLicenceTypeType,
    DriverLicenceValidFrom: ContemporaryDateType.regex(/\d{4}-\d{2}-\d{2}/),
    DriverLicenceIssueCountry: ISOCountryCodeType,
  })
  .passthrough();
const PartialEBulkApplicationData = z
  .object({
    NonApplicationFields: z
      .object({ NoPassportOrDriverLicence: z.boolean() })
      .partial(),
    Title: TitleType,
    Forename: ForenameType.min(1)
      .max(60)
      .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/),
    Middlenames: MiddlenamesStructure,
    PresentSurname: SurnameType.min(1)
      .max(60)
      .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/),
    DateOfBirth: ContemporaryDateType.regex(/\d{4}-\d{2}-\d{2}/),
    Gender: GenderType,
    NiNumber: NationalInsuranceNumberType.min(9)
      .max(9)
      .regex(
        /((([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)|(([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)|([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)))|((([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)|([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*))|([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)))/
      ),
    CurrentAddress: CurrentAddressDateStructure,
    _PreviousAddress_DO_NOT_USE: PreviousAddressDateStructure,
    PreviousAddress: z.array(PreviousAddressDateStructure).max(200),
    AdditionalApplicantDetails: z
      .object({
        BirthSurname: SurnameType.min(1)
          .max(60)
          .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/),
        BirthSurnameUntil: ContemporaryYearType.regex(/\d{4}/),
        BirthTown: ShortAddressLineType.min(1)
          .max(30)
          .regex(
            /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
          ),
        BirthCounty: ShortAddressLineType.min(1)
          .max(30)
          .regex(
            /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
          ),
        BirthCountry: ISOCountryCodeType,
        ContactNumber: PhoneNumberType.min(1)
          .max(30)
          .regex(
            /([A-Z0-9\(\)\-/&]+)|([A-Z0-9\(\)\-/&][A-Z 0-9\(\)\-/&]*[A-Z0-9\(\)\-/&])/
          ),
        UnspentConvictions: YesNoType,
        DeclarationByApplicant: YesNoType,
        LanguagePreference: LanguagePreferenceType,
        OtherForenames: OtherForenames,
        OtherSurnames: OtherSurnames,
        BirthNationality: ISOCountryDescType.min(1)
          .max(30)
          .regex(
            /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
          ),
      })
      .partial(),
    ApplicantIdentityDetails: z
      .object({
        IdentityVerified: YesNoType,
        EvidenceCheckedBy: CheckedByType.min(1)
          .max(60)
          .regex(
            /((([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])|(([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])|([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])))|((([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])|([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-']))|([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])))/
          ),
        PassportDetails: PassportStructure,
        DriverLicenceDetails: DriverLicenceStructure,
      })
      .partial(),
  })
  .partial();
const EBulkApplicationDocument = z.object({
  selected: z.boolean(),
  verified: z.boolean().optional(),
});
const EBulkApplicationDocuments = z
  .object({
    group1: z
      .object({
        passport: EBulkApplicationDocument,
        driverLicence: EBulkApplicationDocument,
        birthCertificate: EBulkApplicationDocument,
        biometricResidencePermit: EBulkApplicationDocument,
        adoptionCertificate: EBulkApplicationDocument,
      })
      .partial(),
    group2a: z
      .object({
        driverLicenceNonEEA: EBulkApplicationDocument,
        driverLicencePaper: EBulkApplicationDocument,
        birthCertificate: EBulkApplicationDocument,
        marriageCivilPartnershipCertificate: EBulkApplicationDocument,
        hmForcesIdCard: EBulkApplicationDocument,
        validFirearmsLicence: EBulkApplicationDocument,
        immigrationDocumentVisaWorkPermit: EBulkApplicationDocument,
      })
      .partial(),
    group2b_last3mo: z
      .object({
        bankStatementUKCI: EBulkApplicationDocument,
        bankStatementUKCI_NonEEA: EBulkApplicationDocument,
        bankStatementOther: EBulkApplicationDocument,
        bankOpeningConfirmationLetter: EBulkApplicationDocument,
        creditCardStatement: EBulkApplicationDocument,
        utilityBill: EBulkApplicationDocument,
        benefitStatement: EBulkApplicationDocument,
        governmentDocumentGivingEntitlement: EBulkApplicationDocument,
        letterSponsorshipFutureEmployer: EBulkApplicationDocument,
      })
      .partial(),
    group2b_last12mo: z
      .object({
        mortgageStatement: EBulkApplicationDocument,
        financialStatement: EBulkApplicationDocument,
        p45_p60: EBulkApplicationDocument,
        councilTaxStatement: EBulkApplicationDocument,
      })
      .partial(),
    group2b_anyTime: z
      .object({
        euNationalIdentityCard: EBulkApplicationDocument,
        irishPassportCard: EBulkApplicationDocument,
        letterHeadteacher: EBulkApplicationDocument,
        passLogoCard: EBulkApplicationDocument,
      })
      .partial(),
    confirmations: z
      .object({
        currentAddressCount: z.number().int(),
        dateOfBirthCount: z.number().int(),
        nameAndNameChangesCount: z.number().int(),
      })
      .partial(),
    application: z.object({ withAdultFirst: z.boolean() }).partial(),
  })
  .partial();
const EBulkApplication = z.object({
  id: z.string(),
  created_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }),
  active: z.boolean(),
  job_id: z.string().uuid().optional(),
  job_role: z.string().optional(),
  customer_id: z.string().uuid().optional(),
  customer_name: z.string().optional(),
  profile_given_name: z.string().optional(),
  profile_family_name: z.string().optional(),
  gdpr_consent: z.boolean().optional(),
  data: PartialEBulkApplicationData,
  status: z.enum([
    "draft",
    "awaiting_customer_approval",
    "awaiting_payment",
    "awaiting_staff_approval",
    "awaiting_submission_to_DBS",
    "awaiting_response_from_DBS",
    "rejected",
    "completed",
  ]),
  rejection_reason: z.string().optional(),
  rejected_by: z.enum(["applicant", "customer", "staff"]).optional(),
  payment_at: z.string().datetime({ offset: true }).optional(),
  payment_method: z.enum(["paypal", "invoice"]).optional(),
  payment_reference: z.string().optional(),
  payment_code: z.string().optional(),
  payment_pence: z.number().int().optional(),
  documents_applicant: EBulkApplicationDocuments.optional(),
  documents_customer: EBulkApplicationDocuments.optional(),
  can_applicant_select_documents: z.boolean().optional(),
  can_applicant_pay: z.boolean().optional(),
  evidence_checked_by_profile_id: z.string().uuid().optional(),
  evidence_checked_at: z.string().datetime({ offset: true }).optional(),
});
const Sort_GetEBulkApplications = z.enum([
  "created_at asc",
  "created_at desc",
  "updated_at asc",
  "updated_at desc",
  "active asc",
  "active desc",
  "status asc",
  "status desc",
  "customer_name asc",
  "customer_name desc",
  "job_role asc",
  "job_role desc",
  "profile_given_name asc",
  "profile_given_name desc",
  "profile_family_name asc",
  "profile_family_name desc",
  "payment_at asc",
  "payment_at desc",
  "payment_method asc",
  "payment_method desc",
  "payment_reference asc",
  "payment_reference desc",
  "gdpr_consent asc",
  "gdpr_consent desc",
]);
const ColumnFilters_GetEBulkApplications = z.array(
  z.union([
    z.object({
      id: z.literal("status"),
      value: z.enum([
        "draft",
        "awaiting_customer_approval",
        "awaiting_payment",
        "awaiting_staff_approval",
        "awaiting_submission_to_DBS",
        "awaiting_response_from_DBS",
        "rejected",
        "completed",
      ]),
    }),
    z.object({
      id: z.literal("payment_method"),
      value: z.enum(["paypal", "invoice"]),
    }),
    z.object({ id: z.literal("active"), value: z.boolean() }),
    z.object({
      id: z.enum(["customer_id", "job_id"]),
      value: z.string().uuid(),
    }),
    z.object({
      id: z.enum([
        "customer_name",
        "job_role",
        "role",
        "payment_reference",
        "profile_given_name",
        "profile_family_name",
      ]),
      value: z.string(),
    }),
    z.object({
      id: z.enum(["created_at", "updated_at", "payment_at"]),
      from: z.string().datetime({ offset: true }).optional(),
      to: z.string().datetime({ offset: true }).optional(),
    }),
  ])
);
const ReturnType_GetEBulkApplications = z.object({
  rows: z.array(EBulkApplication),
  meta: _PageableMeta,
  sort: Sort_GetEBulkApplications.optional(),
  globalFilter: z.string().optional(),
  columnFilters: ColumnFilters_GetEBulkApplications.optional(),
});
const x_dbs_api_clear_fields = z
  .union([z.string(), z.array(z.string())])
  .optional();
const AdditionalApplicantDetailsStructure = z
  .object({
    BirthSurname: SurnameType.min(1)
      .max(60)
      .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/)
      .optional(),
    BirthSurnameUntil: ContemporaryYearType.regex(/\d{4}/).optional(),
    BirthTown: ShortAddressLineType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    BirthCounty: ShortAddressLineType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      )
      .optional(),
    BirthCountry: ISOCountryCodeType,
    ContactNumber: PhoneNumberType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/&]+)|([A-Z0-9\(\)\-/&][A-Z 0-9\(\)\-/&]*[A-Z0-9\(\)\-/&])/
      )
      .optional(),
    UnspentConvictions: YesNoType,
    DeclarationByApplicant: YesNoType,
    LanguagePreference: LanguagePreferenceType,
    OtherForenames: z
      .object({ OtherForename: z.array(OtherNamesStructure).min(1).max(200) })
      .passthrough()
      .optional(),
    OtherSurnames: z
      .object({ OtherSurname: z.array(OtherNamesStructure).min(1).max(200) })
      .passthrough()
      .optional(),
    BirthNationality: ISOCountryDescType.min(1)
      .max(30)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      )
      .optional(),
  })
  .passthrough();
const ApplicantIdentityDetailsStructure = z
  .object({
    IdentityVerified: YesNoType,
    EvidenceCheckedBy: CheckedByType.min(1)
      .max(60)
      .regex(
        /((([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])|(([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])|([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])))|((([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])|([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-']))|([A-Z0-9\-']+)|([A-Z0-9\-'][A-Z 0-9\-']*[A-Z0-9\-'])))/
      ),
    PassportDetails: PassportStructure.optional(),
    DriverLicenceDetails: DriverLicenceStructure.optional(),
  })
  .passthrough();
const ApplicantDetailsStructure = z
  .object({
    Title: TitleType,
    Middlenames: MiddlenamesStructure.optional(),
    PresentSurname: SurnameType.min(1)
      .max(60)
      .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/),
    CurrentAddress: CurrentAddressDateStructure,
    PreviousAddress: z.array(PreviousAddressDateStructure).max(200).optional(),
    DateOfBirth: ContemporaryDateType.regex(/\d{4}-\d{2}-\d{2}/),
    Gender: GenderType,
    NINumber: NationalInsuranceNumberType.min(9)
      .max(9)
      .regex(
        /((([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)|(([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)|([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)))|((([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)|([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*))|([A-Z0-9]+)|([A-Z0-9][A-Z 0-9]*)))/
      )
      .optional(),
    AdditionalApplicantDetails: AdditionalApplicantDetailsStructure,
    ApplicantIdentityDetails: ApplicantIdentityDetailsStructure,
    Forename: ForenameType.min(1)
      .max(60)
      .regex(/([A-Z]+)|([A-Z][A-Z '\-]*[A-Z])/),
  })
  .passthrough();
const EBulkApplicationPayment = z.union([
  z.object({
    payment_method: z.literal("invoice"),
    payment_code: z.string(),
    payment_pence: z.number().int(),
  }),
  z.object({
    payment_method: z.literal("paypal"),
    payment_code: z.string(),
    payment_pence: z.number().int(),
    payment_reference: z.string(),
  }),
]);
const RejectEBulkApplication = z.object({
  reason: z.string(),
  by: z.enum(["applicant", "customer", "staff"]),
});
const PositionAppliedForType = z.string();
const OrganisationNameType = z.string();
const EmploymentDetailsStructure = z
  .object({
    PositionAppliedFor: PositionAppliedForType.min(1)
      .max(60)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
    OrganisationName: OrganisationNameType.min(1)
      .max(60)
      .regex(
        /([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])/
      ),
  })
  .passthrough();
const RbNoType = z.string();
const CsNoType = z.string();
const DisclosureTypeType = z.enum(["standard", "enhanced"]);
const RBApplicationReferenceType = z.string();
const RBdetailsStructure = z
  .object({
    RBNumber: RbNoType.min(11)
      .max(11)
      .regex(/\d{11}/),
    CSigNumber: CsNoType.min(11)
      .max(11)
      .regex(/\d{11}/),
    DisclosureType: DisclosureTypeType,
    WorkingWithVulnerableAdults: YesNoType,
    WorkingWithChildren: YesNoType,
    CurrentAddressDetailsChecked: YesNoType,
    Volunteer: YesNoType,
    WorkingAtHomeAddress: YesNoType,
    RBApplicationReference: RBApplicationReferenceType.min(1)
      .max(30)
      .regex(/[A-Za-z 0-9_\(\)\\\-/'"&]{4,30}x]/),
  })
  .passthrough();
const eBulkApplicationStructure = z
  .object({
    ApplicantDetails: ApplicantDetailsStructure,
    PotentialEmployerDetails: EmploymentDetailsStructure,
    RBdetails: RBdetailsStructure,
  })
  .passthrough();

export const schemas = {
  columnFilters,
  Profile,
  _PageableMeta,
  Sort_GetProfiles,
  ColumnFilters_GetProfiles,
  ReturnType_GetProfiles,
  ErrorResponse,
  ProfileResponse,
  createMyProfile_Body,
  ApplicationStats,
  ReturnType_GetGlobalStats,
  NewInviteStaff,
  MyInviteStaff,
  NewInviteCustomer,
  MyInviteCustomer,
  NewInviteApplicant,
  MyInviteApplicant,
  columnFilters__2,
  _InviteCommon,
  InviteStaff,
  InviteCustomer,
  InviteApplicant,
  Sort_GetInvites,
  ColumnFilters_GetInvites,
  ReturnType_GetInvites,
  createInvite_Body,
  columnFilters__3,
  Customer,
  Sort_GetCustomers,
  ColumnFilters_GetCustomers,
  ReturnType_GetCustomers,
  Field_AddressLineType,
  NewCustomer,
  ReturnType_GetCustomerExpanded,
  columnFilters__4,
  GenericObject,
  NewJob,
  Job,
  Sort_GetJobs,
  ColumnFilters_GetJobs,
  ReturnType_GetJobs,
  ReturnType_GetJobExpanded,
  columnFilters__5,
  TitleType,
  ForenameType,
  MiddlenamesStructure,
  SurnameType,
  ContemporaryDateType,
  GenderType,
  NationalInsuranceNumberType,
  AddressLineType,
  ShortAddressLineType,
  PostCodeType,
  ISOCountryCodeType,
  AddressStructure,
  ContemporaryYearMonthType,
  CurrentAddressDateStructure,
  ResidentDateRangeStructure,
  PreviousAddressDateStructure,
  ContemporaryYearType,
  PhoneNumberType,
  YesNoType,
  LanguagePreferenceType,
  OtherNamesStructure,
  OtherForenames,
  OtherSurnames,
  ISOCountryDescType,
  CheckedByType,
  PassportNumberType,
  PassportStructure,
  DriverLicenceNumberType,
  DriverLicenceTypeType,
  DriverLicenceStructure,
  PartialEBulkApplicationData,
  EBulkApplicationDocument,
  EBulkApplicationDocuments,
  EBulkApplication,
  Sort_GetEBulkApplications,
  ColumnFilters_GetEBulkApplications,
  ReturnType_GetEBulkApplications,
  x_dbs_api_clear_fields,
  AdditionalApplicantDetailsStructure,
  ApplicantIdentityDetailsStructure,
  ApplicantDetailsStructure,
  EBulkApplicationPayment,
  RejectEBulkApplication,
  PositionAppliedForType,
  OrganisationNameType,
  EmploymentDetailsStructure,
  RbNoType,
  CsNoType,
  DisclosureTypeType,
  RBApplicationReferenceType,
  RBdetailsStructure,
  eBulkApplicationStructure,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/customer",
    alias: "getCustomers",
    description: `Get all the customers the current user can see. Used for customer administration purposes. Staff see everyones. Customers see only theirs. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "page_index",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z
          .enum([
            "name asc",
            "name desc",
            "created_at asc",
            "created_at desc",
            "updated_at asc",
            "updated_at desc",
            "active asc",
            "active desc",
          ])
          .optional(),
      },
      {
        name: "globalFilter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "columnFilters",
        type: "Query",
        schema: columnFilters__3,
      },
    ],
    response: ReturnType_GetCustomers,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "post",
    path: "/customer",
    alias: "createCustomer",
    description: `Create a new customer. Staff can customers. Customers don&#x27;t use this. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The customer to create`,
        type: "Body",
        schema: NewCustomer,
      },
    ],
    response: z.object({ inviteId: z.string().optional() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/customer/:id",
    alias: "getCustomerById",
    description: `Get the specified customer, showing details for table customer row expansion. Staff can see all customers. Customers can see themselves.`,
    requestFormat: "json",
    response: Customer,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/customer/:id/expand",
    alias: "getCustomerExpanded",
    description: `Get the specified customer, showing details for table customer row expansion. Staff can see all customers. Customers can see themselves.`,
    requestFormat: "json",
    response: ReturnType_GetCustomerExpanded,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/eBulkApplication",
    alias: "getEBulkApplications",
    description: `Get all the eBulkApplications the current user can see. Used for administration purposes. Staff see everyones. Customers see only theirs. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "page_index",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z
          .enum([
            "created_at asc",
            "created_at desc",
            "updated_at asc",
            "updated_at desc",
            "active asc",
            "active desc",
            "status asc",
            "status desc",
            "customer_name asc",
            "customer_name desc",
            "job_role asc",
            "job_role desc",
            "profile_given_name asc",
            "profile_given_name desc",
            "profile_family_name asc",
            "profile_family_name desc",
            "payment_at asc",
            "payment_at desc",
            "payment_method asc",
            "payment_method desc",
            "payment_reference asc",
            "payment_reference desc",
            "gdpr_consent asc",
            "gdpr_consent desc",
          ])
          .optional(),
      },
      {
        name: "globalFilter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "columnFilters",
        type: "Query",
        schema: columnFilters__5,
      },
    ],
    response: ReturnType_GetEBulkApplications,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/eBulkApplicationsBatch",
    alias: "unused",
    description: `NOTE: This endpoint is unused. It&#x27;s here to get &quot;#/components/schemas/CompleteEBulkApplication&quot; into the generated zod client.`,
    requestFormat: "json",
    response: z.array(eBulkApplicationStructure),
  },
  {
    method: "post",
    path: "/eBulkApplicationsBatch/send",
    alias: "eBulkApplicationsBatchSend",
    description: `Submit all the eBulk applications in the &#x60;awaiting_submission_to_DBS&#x60; to the DBS. Staff only.`,
    requestFormat: "json",
    response: z.object({ applications_sent: z.number().int() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/invite",
    alias: "getInvites",
    description: `Get all the invites the current user can see. Used for invite administration purposes. Staff see everyones. Customers see only theirs. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "page_index",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z
          .enum([
            "type asc",
            "type desc",
            "active asc",
            "active desc",
            "accepted_at asc",
            "accepted_at desc",
            "email asc",
            "email desc",
            "customer_name asc",
            "customer_name desc",
            "job_role asc",
            "job_role desc",
            "invited_at asc",
            "invited_at desc",
            "invited_by_profile_name asc",
            "invited_by_profile_name desc",
          ])
          .optional(),
      },
      {
        name: "globalFilter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "columnFilters",
        type: "Query",
        schema: columnFilters__2,
      },
    ],
    response: ReturnType_GetInvites,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "post",
    path: "/invite",
    alias: "createInvite",
    description: `Create a new invite. Staff can invite peers to be staff, customers, and applicants under a customer. Customers can invite their peers, and their applicants. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The invite to create`,
        type: "Body",
        schema: createInvite_Body,
      },
    ],
    response: z.object({ inviteId: z.string() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "post",
    path: "/invite/:id/accept",
    alias: "acceptInvite",
    description: `Accept a given invite for the current user. Uses the Auth0 ID token for the user&#x27;s email address. Matching is done by logged in email address, with invited effect attached to the current user&#x27;s profile. If the invite is for a staff member, the current user&#x27;s profile&#x27;s &#x60;is_staff&#x60; is set to true. If the invite is for a customer, the customer&#x27;s ID is added to the current user&#x27;s profile&#x27;s &#x60;admin_customer_ids&#x60;. If the invite is for an applicant, the customer&#x27;s ID is added to the current user&#x27;s profile&#x27;s &#x60;known_customer_ids&#x60;, and an application is added. The frontend should then get a new profile JWT token and refetch the applications to reflect these changes.`,
    requestFormat: "json",
    parameters: [
      {
        name: "x-auth0-id-token",
        type: "Header",
        schema: z.string(),
      },
    ],
    response: z.object({ accepted: z.boolean() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "delete",
    path: "/invite/:id/rescind",
    alias: "rescindInvite",
    description: `Rescind a given invite. Used for invite administration purposes. Staff can rescind any invite. Customers can rescind their invites (even if issued by staff) for both customers and applicants.`,
    requestFormat: "json",
    response: z.object({ rescinded: z.boolean() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/invite/my",
    alias: "getMyInvites",
    description: `Get the various invites for the current user to accept. Uses the Auth0 ID token for the user&#x27;s email address, as matching is done by logged in email address.`,
    requestFormat: "json",
    parameters: [
      {
        name: "x-auth0-id-token",
        type: "Header",
        schema: z.string(),
      },
    ],
    response: z.array(
      z.union([MyInviteStaff, MyInviteCustomer, MyInviteApplicant])
    ),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/job",
    alias: "getJobs",
    description: `Get all the jobs the current user can see. Used for job administration purposes. Staff see everyones. Customers see only theirs. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "page_index",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z
          .enum([
            "role asc",
            "role desc",
            "application_workforce asc",
            "application_workforce desc",
            "application_type asc",
            "application_type desc",
            "working_with_adults asc",
            "working_with_adults desc",
            "working_with_children asc",
            "working_with_children desc",
            "working_at_home_address asc",
            "working_at_home_address desc",
            "volunteer asc",
            "volunteer desc",
            "customer_name asc",
            "customer_name desc",
            "created_at asc",
            "created_at desc",
            "updated_at asc",
            "updated_at desc",
            "active asc",
            "active desc",
          ])
          .optional(),
      },
      {
        name: "globalFilter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "columnFilters",
        type: "Query",
        schema: columnFilters__4,
      },
    ],
    response: ReturnType_GetJobs,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "post",
    path: "/job",
    alias: "createJob",
    description: `Create a new job. They are only editable before the first submission. Staff can jobs. Customers can create their own. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The job to create`,
        type: "Body",
        schema: NewJob,
      },
    ],
    response: z.object({ inviteId: z.string().optional() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/job/:id",
    alias: "getJobById",
    description: `Get the specified job. Staff can see all jobs. Customers can see their own jobs.`,
    requestFormat: "json",
    response: Job,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/job/:id/expand",
    alias: "getJobExpanded",
    description: `Get the specified job, showing details for table job row expansion. Staff can see all jobs. Customers can see their own jobs.`,
    requestFormat: "json",
    response: ReturnType_GetJobExpanded,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/profile",
    alias: "getProfiles",
    description: `Get all the profiles. Staff see everyones. Customers see only theirs. Applicants don&#x27;t use this.`,
    requestFormat: "json",
    parameters: [
      {
        name: "page_index",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z
          .string()
          .regex(/^[0-9]+$/)
          .optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z
          .enum([
            "created_at asc",
            "created_at desc",
            "updated_at asc",
            "updated_at desc",
            "active asc",
            "active desc",
          ])
          .optional(),
      },
      {
        name: "globalFilter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "columnFilters",
        type: "Query",
        schema: columnFilters,
      },
    ],
    response: ReturnType_GetProfiles,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "patch",
    path: "/profile/:id/countersignatory",
    alias: "addCountersignatoryNumberToProfile",
    description: `Staff can add a countersignatory number to a staff profile.`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "countersignatory_number",
        type: "Query",
        schema: z.string().regex(/^[0-9]{11}$/),
      },
    ],
    response: z.object({ message: z.string().optional() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/profile/my",
    alias: "getMyProfile",
    description: `Get profile information for the current user, optionally with a profile JWT token.`,
    requestFormat: "json",
    parameters: [
      {
        name: "jwt",
        type: "Query",
        schema: z.boolean().optional(),
      },
    ],
    response: ProfileResponse,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `Profile not found for this identity. User needs to create a profile first.`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "post",
    path: "/profile/my",
    alias: "createMyProfile",
    description: `Create a new profile for the current user.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The basics of new profile`,
        type: "Body",
        schema: createMyProfile_Body,
      },
      {
        name: "x-auth0-id-token",
        type: "Header",
        schema: z.string(),
      },
    ],
    response: z.object({ message: z.string().optional() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 403,
        description: `Profile already exists for this identity.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "patch",
    path: "/profile/my",
    alias: "updateMyProfile",
    description: `Create a new profile for the current user.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Updateable profile aspects`,
        type: "Body",
        schema: createMyProfile_Body,
      },
    ],
    response: z.object({ message: z.string().optional() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/public/eBulkApplication/:id",
    alias: "getEBulkApplicationByID",
    description: `Get a DBS eBulk application by ID (access checked against staff/customer/applicant).`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "patch",
    path: "/public/eBulkApplication/:id",
    alias: "updateMyEBulkApplicationByID",
    description: `Update a DBS eBulk application for the current user.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Partial Applicant Details`,
        type: "Body",
        schema: PartialEBulkApplicationData,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "x-dbs-api-clear-fields",
        type: "Header",
        schema: x_dbs_api_clear_fields,
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/public/eBulkApplication/:id",
    alias: "completeMyEBulkApplicationByID",
    description: `Complete a DBS eBulk application for the current user.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Full Applicant Details`,
        type: "Body",
        schema: ApplicantDetailsStructure,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/public/eBulkApplication/:id/countersign",
    alias: "countersignEBulkApplicationByID",
    description: `Countersign an eBulk application. Can be by staff while in the awaiting_staff_approval state. Requires user to have a countersignatory_number on their profile.`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "patch",
    path: "/public/eBulkApplication/:id/documents-applicant",
    alias: "updateMyEBulkApplicationDocumentsApplicantByID",
    description: `Update the documents an applicant intends to bring for a DBS eBulk application for the current user.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The documents that the applicant intends to bring`,
        type: "Body",
        schema: EBulkApplicationDocuments,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "patch",
    path: "/public/eBulkApplication/:id/documents-customer",
    alias: "confirmMyEBulkApplicationDocumentsCustomerByID",
    description: `Confirm the documents an customer has verified for their applicant&#x27;s DBS eBulk application. Moves to payment.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The documents that the applicant brought and were verified by the customer`,
        type: "Body",
        schema: EBulkApplicationDocuments,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/public/eBulkApplication/:id/give-gdpr-consent",
    alias: "consentMyEBulkApplicationByID",
    description: `Mark an eBulk application as having GDPR consent given.`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/public/eBulkApplication/:id/payment",
    alias: "payForEBulkApplicationByID",
    description: `Record a payment against an application, while in &#x60;awaiting_payment&#x60; status. Staff payment is currently unsupported. Customers can pay for their applications, by any supported method, including invoice (if enabled for the customer). Applicants can pay for this own applications, but cannot choose invoice.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `The documents that the applicant intends to bring`,
        type: "Body",
        schema: EBulkApplicationPayment,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/public/eBulkApplication/:id/reject",
    alias: "rejectMyEBulkApplicationByID",
    description: `Reject an eBulk application. Can be done any time before payment by appliant / customer, or by staff at any time.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Rejection metadata`,
        type: "Body",
        schema: RejectEBulkApplication,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: EBulkApplication,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
      {
        status: 404,
        description: `DBS eBulk application by this ID not found for the current user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "get",
    path: "/public/eBulkApplication/my",
    alias: "getMyEBulkApplications",
    description: `Get DBS eBulk applications for the current user.`,
    requestFormat: "json",
    response: z.array(EBulkApplication),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/stats/global",
    alias: "getGlobalStats",
    description: `Get global stats. Only accessible by staff`,
    requestFormat: "json",
    parameters: [
      {
        name: "customer_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: ReturnType_GetGlobalStats,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "get",
    path: "/system-info",
    alias: "getSystemInfo",
    description: `Get system information, used as a health check.`,
    requestFormat: "json",
    response: z.object({ alive: z.boolean() }),
  },
  {
    method: "get",
    path: "/test/data/snapshot",
    alias: "getTestDataSnapshots",
    description: `Get a list of the DB snapshots we have available for testing.`,
    requestFormat: "json",
    response: z.array(z.object({ name: z.string() })),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
  {
    method: "post",
    path: "/test/data/snapshot",
    alias: "restoreTestDataSnapshot",
    description: `Restore a DB snapshots from those have available for testing.`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.object({ restored: z.boolean() }),
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: ErrorResponse,
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
