import React, { useEffect } from "react";
import { ActionFunction, redirect } from "react-router-dom";
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient";
import CustomerAutocomplete from "../components/CustomerAutocomplete";
import { Alert, AlertTitle, Box, Container, Typography } from "@mui/material";
import { z } from "zod";
import { Form } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CheckboxElement, SelectElement, TextFieldElement, TextareaAutosizeElement } from "react-hook-form-mui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Helmet } from "react-helmet-async";
import { QueryClient } from "@tanstack/react-query";
import { schemas } from "../generated/api/client";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import { TestingInfoBlockFormErrors } from "../components/TestingInfo";

const zFormSchema = schemas.NewJob
type FormSchema = z.infer<typeof zFormSchema>

export const Element: React.FC = () => {
  // Build the Form
  const form = useForm<FormSchema>({
    resolver: zodResolver(
      zFormSchema
        .superRefine((value, context) => {
          // Working with Children (barred list) requires Enhanced application type
          if (value.working_with_children && value.application_type !== 'enhanced') {
            context.addIssue({
              code: 'custom',
              message: 'Working with Children requires Enhanced application type',
              path: ['application_type'],
            })
            context.addIssue({
              code: 'custom',
              message: 'Working with Children requires Enhanced application type',
              path: ['working_with_children'],
            })
          }
          // Working with Adults (barred list) requires Enhanced application type
          if (value.working_with_adults && value.application_type !== 'enhanced') {
            context.addIssue({
              code: 'custom',
              message: 'Working with Adults requires Enhanced application type',
              path: ['application_type'],
            })
            context.addIssue({
              code: 'custom',
              message: 'Working with Adults requires Enhanced application type',
              path: ['working_with_adults'],
            })
          }
          // Working at Home Address requires Enhanced application type
          if (value.working_at_home_address && value.application_type !== 'enhanced') {
            context.addIssue({
              code: 'custom',
              message: 'Working at Home Address requires Enhanced application type',
              path: ['application_type'],
            })
            context.addIssue({
              code: 'custom',
              message: 'Working at Home Address requires Enhanced application type',
              path: ['working_at_home_address'],
            })
          }
        })
    ),
    mode: 'onBlur',
    defaultValues: {
      customer_id: '',
      working_with_adults: false,
      working_with_children: false,
      working_at_home_address: false,
      volunteer: false,
      with_adult_first: true,
    },
  })

  const customer_id = form.watch('customer_id')
  const customer = apiClientHooks.useGetCustomerById({
    params: { id: customer_id },
  }, {
    enabled: !!customer_id,
  }).data
  const isCustomerSectorHealthcare = customer?.sector_healthcare ?? false

  const application_workforce = form.watch('application_workforce')
  const isApplicationWorkforceAdult = application_workforce === 'adult_only'

  const application_type = form.watch('application_type')
  const isApplicationTypeEnhanced = application_type === 'enhanced'

  const working_with_adults = form.watch('working_with_adults')
  const isWorkingWithAdults = working_with_adults === true

  const isAdultFirstEligible = isCustomerSectorHealthcare && isApplicationWorkforceAdult && isApplicationTypeEnhanced && isWorkingWithAdults
  const with_adult_first = form.watch('with_adult_first')
  useEffect(() => {
    // Ensure that the with_adult_first is false if the role is not eligible for Adult First
    if (!isAdultFirstEligible && with_adult_first === true) {
      form.setValue('with_adult_first', false)
    }
  }, [form, isAdultFirstEligible, with_adult_first])

  // Build the output
  const textFieldElementCommonProps = {
    control: form.control,
    margin: "normal" as const,
    fullWidth: true,
  }

  return <>
    <Helmet>
      <title>New Job Role</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={3}>
          New Job Role
        </Typography>

        <Form method="post">
          <CustomerAutocomplete
            control={form.control}
            label="Customer"
            name="customer_id"
          />

          <TextFieldElement {...textFieldElementCommonProps} label="Job Role" name="role" inputProps={{ 'data-testid': "role" }} />

          <Alert severity="info" sx={{ marginTop: 5 }}>
            Choose the workforce that this role <strong>requires</strong>.
            <br /><br />
            If you need help, please contact us for guidance.
          </Alert>

          <SelectElement
            {...textFieldElementCommonProps}
            label="Application Workforce"
            name="application_workforce"
            options={[
              { id: 'adult_only', label: 'Adult Workforce' },
              { id: 'child_only', label: 'Child Workforce' },
              { id: 'adult_and_child', label: 'Adult and Child Workforce' },
              { id: 'other', label: 'Other Workforce' },
            ]}
            data-testid="application_workforce"
          />

          <TextareaAutosizeElement
            {...textFieldElementCommonProps}
            label="Justification for Application Workforce"
            name="application_workforce_justification"
            data-testid="application_workforce_justification"
            rows={5}
          />

          <Alert severity="info" sx={{ marginTop: 5 }}>
            Choose the application type that the role <strong>requires</strong>.
            You will need to justify this with reference to appropriate legislation.
            <br /><br />
            If you need help, please contact us for guidance.
          </Alert>

          <SelectElement
            {...textFieldElementCommonProps}
            label="Application Type"
            name="application_type"
            data-testid="application_type"
            options={[
              { id: 'standard', label: 'Standard' },
              { id: 'enhanced', label: 'Enhanced' },
            ]}
          />

          <TextareaAutosizeElement
            {...textFieldElementCommonProps}
            label="Justification for Application Type"
            name="application_type_justification"
            data-testid="application_type_justification"
            rows={5}
          />

          <Alert severity="info" sx={{ marginTop: 5 }}>
            Please select this only if the role requires this check under the law.
            Justification of what aspect of legislation supports this check is required.
            <br /><br />
            If you need help, please contact us for guidance.
          </Alert>

          <CheckboxElement
            control={form.control}
            label="Working with Adults (barred list)"
            name="working_with_adults"
            data-testid="working_with_adults"
            inputProps={{ name: 'working_with_adults' }}
          />

          <TextareaAutosizeElement
            {...textFieldElementCommonProps}
            label="Justification for Working with Adults (barred list)"
            name="working_with_adults_justification"
            data-testid="working_with_adults_justification"
            rows={5}
          />

          <Alert severity="info" sx={{ marginTop: 5 }}>
            <AlertTitle>Adult First (ISA)</AlertTitle>
            {isAdultFirstEligible
              ? <>
                This role is eligible for the Adult First check.
                Please select this if you wish to apply for the Adult First check.
                <br />
                <CheckboxElement
                  control={form.control}
                  label="With Adult First"
                  name="with_adult_first"
                  data-testid="with_adult_first"
                  inputProps={{ name: 'with_adult_first' }}
                />
              </>
              : <>
                This role is not eligible for the Adult First check. Contact us for guidance if you believe this is incorrect.
                <br />
                <CheckboxElement
                  control={form.control}
                  label="With Adult First"
                  disabled={true}
                  name="with_adult_first"
                  data-testid="with_adult_first"
                  inputProps={{ name: 'with_adult_first' }}
                />

              </>
            }
          </Alert>

          <Alert severity="info" sx={{ marginTop: 5 }}>
            Please select this only if the role requires this check under the law.
            Justification of what aspect of legislation supports this check is required.
            <br /><br />
            If you need help, please contact us for guidance.
          </Alert>

          <CheckboxElement
            control={form.control}
            label="Working with Children (barred list)"
            name="working_with_children"
            data-testid="working_with_children"
            inputProps={{ name: 'working_with_children' }}
          />

          <TextareaAutosizeElement
            {...textFieldElementCommonProps}
            label="Justification for Working with Children (barred list)"
            name="working_with_children_justification"
            data-testid="working_with_children_justification"
            rows={5}
          />

          <Alert severity="info" sx={{ marginTop: 5 }}>
            Does this position involve working with children or adults at the applicant's home address?
            <br /><br />
            If you need help, please contact us for guidance.
          </Alert>

          <CheckboxElement
            control={form.control}
            label="Working at Home Address"
            name="working_at_home_address"
            data-testid="working_at_home_address"
            inputProps={{ name: 'working_at_home_address' }}
          />

          <TextareaAutosizeElement
            {...textFieldElementCommonProps}
            label="Justification for Working from Home"
            name="working_at_home_address_justification"
            data-testid="working_at_home_address_justification"
            rows={5}
          />

          <Alert severity="info" sx={{ marginTop: 5 }}>
            By selecting this, you confirm that this role meets the DBS definition of a free of charge volunteer application.
            Please note that DBS may recover the application fee, if this box is marked in error and this could result in the cancellation of your DBS registration.
          </Alert>

          <CheckboxElement
            control={form.control}
            label="Volunteer"
            name="volunteer"
            data-testid="volunteer"
            inputProps={{ name: 'volunteer' }}
          />

          <TestingInfoBlockFormErrors form={form} />

          <SingleClickWaitingButton
            type="submit"
            fullWidth
            variant="contained"
            color={form.formState.isValid ? 'primary' : 'inherit'}
            onClick={async event => {
              const isValid = await form.trigger()
              if (!isValid) {
                console.warn("Form is not valid", form.formState.errors)
                event.preventDefault()
              }
            }}
            sx={{ mt: 3, mb: 2 }}
          >
            Create
          </SingleClickWaitingButton>
        </Form>
      </Box>
    </Container>
  </>
}

export const action: (queryClient: QueryClient) => ActionFunction = queryClient => async ({ params, request }) => {
  const formData = await request.formData()
  const formObject = Object.fromEntries(formData)
  const FormObjectRetyped = {
    ...formObject,
    // The above checkboxes are either 'true' if checked, or absent if not
    working_with_adults: formObject.working_with_adults === 'true',
    working_with_children: formObject.working_with_children === 'true',
    working_at_home_address: formObject.working_at_home_address === 'true',
    volunteer: formObject.volunteer === 'true',
    with_adult_first: formObject.with_adult_first === 'true',
  }
  const newJobData = zFormSchema.parse(FormObjectRetyped)

  const createdJob = await apiClient.createJob(newJobData)
  console.log("AddJob.action: ", { newJobData, createdJob })

  // Don't wait for this to complete
  queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getJobs') })
  return redirect(`/jobs`)
}

export default Element
